export const educationData = [
    {
        id: 1,
        institution: 'GIKI School and College',
        course: 'Higher Secondary Education',
        startYear: '2011',
        endYear: '2013'
    },
    {
        id: 2,
        institution: 'FAST National University of Computer and Emerging Sciences',
        course: 'Bachelors in Computer Science',
        startYear: '2013',
        endYear: '2017'
    },
]